.productContainer {
  margin: 50px 0;
}
.slidercontainer {
  width: 100%;
  height: 200px;
  margin: 0 auto;
  position: relative;
}
.slidercontainer img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.productContainer .title {
  font-size: 20px;
  margin-top: 50px;
}

.productContainer .sku {
  font-size: 16px;
  font-weight: bold;
  margin: 30px 0;
}

.productContainer .skuspan {
  font-weight: lighter;
  text-transform: uppercase;
}

.productContainer .price {
  margin: 30px 0;
  font-size: 25px;
  font-weight: bold;
  display: block;
}

.productDescription {
  border-top: 1px solid #cecece;
  margin-top: 40px;
  padding: 15px 0;
}

.productDescription h3 {
  font-weight: bold;
  font-size: 22px;
}

.productDescription p {
  font-size: 15px;
  line-height: 28px;
  letter-spacing: 1px;
}

@media only screen and (min-width: 768px) {
  .slidercontainer {
    height: 450px;
  }

  .productContainer .title {
    margin-top: 0;
  }
}
