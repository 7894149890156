.productsCard {
  width: 100%;
}

.productsCard img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.productTitle {
  font-weight: bold;
  font-size: 14px;
}

@media only screen and (min-width: 728px) {
  .productsCard {
    width: 275px;
    height: 275px;
    margin-bottom: 140px;
  }
  .productTitle {
    font-size: 15px;
  }
  .productPrice {
    font-weight: bold;
    font-size: 14px;
  }
}
