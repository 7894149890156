.checkout {
  display: flex;
  flex-direction: column-reverse;
}
.form {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.form .formItems {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.form .formItem {
  margin: 5px 0;
}

@media only screen and (min-width: 768px) {
  .checkout {
    flex-direction: row;
    flex: 1;
  }
  .form {
    width: 99%;
  }
  .form .formItems {
    flex-direction: row;
    width: 100%;
    gap: 1%;
  }
  .form .formItem {
    width: 100%;
  }
}
